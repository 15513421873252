<template>
  <div id="help-section">
    <breadcrumb class="mt-3" :item="$route.matched" :current="$route.path" />

    <notification
      v-if="notification.isActive"
      :type="notification.type"
      :message="notification.message"
    />

    <div class="custom-container">
      <div class="row">
        <div class="col-2 text-left">
          <h5 class="mt-1" style="margin: 0">Help Section</h5>
        </div>

        <div class="col-4"></div>

        <div class="col">
          <b-input-group>
            <b-form-select
              v-model="selectedCategory"
              :options="categories"
              size="sm"
              class="mr-2"
            ></b-form-select>

            <b-form-input
              size="sm"
              name="search"
              placeholder="Search"
            ></b-form-input>
            <b-input-group-append>
              <b-button size="sm" variant="info">
                <b-icon class="mr-1" icon="search" aria-hidden="true"></b-icon>
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </div>
      </div>

      <b-table
        class="mt-3"
        thead-class="th-label"
        tbody-class="tr-label"
        bordered
        striped
        :items="forms"
        :fields="fields"
        :busy="loading"
      >
        <template #table-busy>
          <div class="text-center my-2">
            <b-spinner class="align-middle mr-2"></b-spinner>
            <strong>Fetching Data...</strong>
          </div>
        </template>

        <!-- to overview column -->
        <template #cell(actions)>
          <a href="javascript:void(0)" @click="download()"
            >Download <b-icon icon="cloud-download" aria-hidden="true"></b-icon
          ></a>
        </template>
      </b-table>

      <div>
        <b-pagination align="right" :total-rows="10" size="sm"></b-pagination>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: () => {
    return {
      selectedCategory: null,
      categories: [
        { text: "Filter by Category", value: null },
        { text: "Examination Protocols", value: "Examination Protocols" },
        { text: "Guidelines", value: "Qualities of Life Questionnaires" },
        {
          text: "Patient Information / Informed Consent",
          value: "Patient Information / Informed Consent",
        },
        { text: "Kostengutsprachegesuch", value: "Kostengutsprachegesuch" },
      ],
      fields: [
        {
          key: "category",
          label: "Category",
        },
        {
          key: "description",
          label: "Description",
        },
        {
          key: "fileName",
          label: "File Name",
        },
        {
          key: "dateCreated",
          label: "Date Created",
        },
        {
          key: "size",
          label: "File Size",
        },
        {
          key: "actions",
          label: "Actions",
        },
      ],
      forms: [],
      loading: false,
      notification: {
        isActive: false,
        type: "",
        message: "",
      },
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      this.loading = true;

      this.$requestService.get(
        this.$apiEndpoints.helpSection.get,
        {},
        this.handleGetDataSuccess,
        this.handleFailed
      );
    },
    handleGetDataSuccess(response) {
      this.forms = response.data;
      this.loading = false;
    },
    handleFailed(e) {
      console.log(e)
    },
    download() {
      const h = this.$createElement;

      const vNodesMsg = h("p", { class: ["text-center", "mb-0"] }, [
        "Downloading data in the background",
        h("b-spinner", { class: ["ml-2"], props: { small: true } }),
      ]);

      this.$bvToast.toast([vNodesMsg], {
        id: "loadingToast",
        toaster: "b-toaster-top-right",
        title: "Processing",
        solid: true,
        appendToast: true,
      });
    },
  },
};
</script>

<style></style>
